import { Injectable } from '@angular/core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { AppStateService } from '../app-state/app-state.service';
import { AuthService } from '../../../modules/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
    private authService: AuthService,
  ) { }

  getContent(items: string[]): Observable<boolean> {
    const data = {
      items
    };
    const statement = `
      query getContentItems($input: ContentItemsInput!) {
        getContentItems(input: $input) {
          name
          content
        }
      }
    `;
    return this.authService.isLoggedIn()
      .pipe(
        switchMap(authState => {
          return this.apiService
            .graphql<ContentItem[]>({ statement, variables: { input: data }, type: 'getContentItems', iam: !authState.isLoggedIn })
            .pipe(
              tap(items => {
                const entries: any = {};
                items.forEach(item => {
                  const [key, name] = item.name.split(':');
                  if (!entries[key]) {
                    entries[key] = {};
                  }
                  entries[key][name] = item.content
                });
                this.appState.contentItemCollection = {
                  ...this.appState.contentItemCollection,
                  ...entries
                };
              }),
              map(val => true),
            )
        })
      )
  }
}

export interface ContentItemCollection {
  [key: string]: {
    [key: string]: string;
  }
}

export interface ContentItem {
  name: string;
  content: string;
}